
























import Vue from "vue";
import config from "../config.json";

export default Vue.extend({
  name: "About",
  data() {
    return {
      title: "",
      desc: "",
    };
  },
  created() {
    this.title = config.about.title;
    this.desc = config.about.desc;
  },
});
